.carousel-track {
    display: flex;
    flex-flow: wrap;
}
.carousel-track .card:last-child{margin-right:0}
.carousel-track .card {
    background: #2B4378;
    border-radius: 20px;
    color: #fff;
    height: 193px;
    margin-right: 20px;
    border: 0;
    width:23.77%;
  }
  
  .carousel-track .card .card-body {
    padding: 1.5rem;
  }
  
  .icon-div {
    background: #8D9DDB;
    width: 63px;
    height: 63px;
    border-radius: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-track .card .card-body h4 {
    font-family: 'robotomedium';
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
  }
  
  .carousel-track .card .card-body h5 {  
    font-size: 20px;
    font-weight: normal;
  }
  


/* @media only screen and (max-width:1600px) {
    .ulcards li .card1 .card-body h4 {  
        font-size: 24px !important;       
    }

} */
@media only screen and (max-width:1100px) {

    .staff-onboarding-tabs {
        width: 100%;}

    .carousel-track .card {      
        margin-right: 20px;
        margin-bottom: 20px;    
        width:47%;
      }

    .popover {
        width: 90%;
        max-width: 90%;
    }
    ul.list-dropdown-custom.p-0.pb-2 {
        padding: 10px !important;
    }
    .ulcards li {
        width: 50%;
        margin-bottom: 20px;;
    }
}

@media only screen and (max-width:767px) {
    .section-small ul.nav.nav-tabs li, .section-small ul.nav.nav-tabs li button{width: 100%;}
    .section-small ul.nav.nav-tabs {
        height: auto;
    }
    .innertab-sec.paymenttab ul li.nav-item::after{display: none;}
    .d-flex.mb-3.mt-3{display: inherit !important;}
    .d-flex.mb-3.mt-3 input, .d-flex.mb-3.mt-3 .btn, .d-flex.mb-3.mt-3 select{width:100%;margin-bottom: 5px;;}
    .basic-box .row .col {
        flex: auto;
    }
    .carousel-track .card {      
        margin-right: 0px;       
        width:100%;
      }
    .ulcards li {
        width: 100%;
    }
    ul.list-dropdown-custom li {
        width: 100%;
    }    
    .cnavbar {
        padding: 10px 0;
    }
    .inner-section {
        max-height: inherit !important;
    }
    .inner-section .branch_card {
        margin-bottom: 10px;
    }
    .me-auto.navbar-nav {
        margin-bottom: 5px;
        width: 100%;
    }
    span.navbar-brand {
        width: 100%;
    }
    .cnavbar .d-flex.align-items-center {
        flex-flow: wrap;
    }
    .input-group {
        width: 100%;
        margin-bottom: 5px !important;
    }
    div#basic-navbar-nav {
        display: block;
    }
    #basic-navbar-nav .me-auto.navbar-nav {
        flex-flow: wrap;
    }
    #basic-navbar-nav .me-auto.navbar-nav .nav-item.dropdown {
        width: 100%;
    }
    .content-tabs ul.custom-dlex-tabs.nav.nav-tabs {
        flex-flow: wrap;
    }
    .content-tabs ul.custom-dlex-tabs.nav.nav-tabs li,
    .content-tabs ul.custom-dlex-tabs.nav.nav-tabs li .nav-link {
        width: 100%
    }
    .counter {
        width: 100%;
        margin-bottom: 20px;
    }
    .content-tabs .tab-content {
        padding: 20px 0px;
    }
    .d-flex.justify-content-end.mb-3 {
        flex-flow: wrap;
    }
    .tab-content .outline-cbtn,
    .tab-content .outline-select {
        width: 100%;
        margin-bottom: 5px;
    }

}