.initial-setup-banner-btn {
  height: 26px !important;
  min-width: 60px !important;
  padding-inline: 8px !important;
  font-size: var(--theme-context-fs) !important;
  text-align: center !important;
  border: 0 !important;
  border-radius: 4px !important;
  background-color: var(--theme-primary-color) !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.initial-setup-banner-btn:hover {
  background-color: var(--theme-secondary-focus-color) !important;
}

:is(.initial-setup-banner-btn) img {
  height: 17px;
  width: 17px;
}

.initial-setup-banner-close-btn {
  background-color: #fff;
  border: 0 !important;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.initial-setup-margin-spacing {
  margin: 0 7.25rem;
}

@media only screen and (min-width: 1000px) {
  .initial-setup-margin-spacing {
    margin: 0 6rem ;
  }
}

@media only screen and (min-width: 1400px) {
  .initial-setup-margin-spacing {
    margin: 0 7.25rem ;
  }
}
