.style-1 {
  font-size: 14px;
  color: var(--theme-login-secondary-color) !important;
}

.style-2 {
  font-size: 14px;
  font-weight: 600;
  color: var(--theme-primary-color) !important;
}

.main-auth-background{
  height: auto;
  background: url("../../../public/images/main-auth-background.svg");
}

.screen-org .slick-slide .default-img {
  display: block;
  height: 48vh;
  width: 100%;
}
.screen-org .slick-slide .org-images-container {
  display: block;
  height: 82vh;
  background-size: contain;
  width: 100%;
}

.screen-org .slick-slider{
  height: 5vh;
  padding-top: 4%;
}

/* .screen-org .slick-dots {
  position: absolute;
  bottom: -124px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
} */

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% 45vh;
  background-position: center;
  background-position-y: 20%;
  height: 50vh;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.auth-main-screen{
  width: 75%;
  height: 100vh;
  margin-inline: auto;
  /* padding: 8% 0; */
  align-items: center;
}

.screen-org {
  width: 58%;
  display: flex;
  flex-direction: column;
  /* background-color: #FFF; */
  /* background: url("../../../public/images/org-select-background.svg"); */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% 45vh;
  background-position: center;
  background-position-y: 20%;
  height: 82vh;
  overflow: auto;
  overflow-x: hidden;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: relative;
}


@keyframes slider {
  0%, 100% {
    transform: translateX(0);
    animation-timing-function: ease;
  }
  33.33% {
    transform: translateX(-100%);
    animation-timing-function: step-end;
  }
  66.66% {
    transform: translateX(100%);
    animation-timing-function: ease-in-out;
  }
}

.screen-org .slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 45vh;
  background-position: center;
  background-position-y: 20%;
  animation: slider 9s infinite;
}

.screen-org .slide:nth-child(1) {
  background-image: url("../../../public/images/org-select-background.svg");
  animation-delay: 0s;
}

.screen-org .slide:nth-child(2) {
  background-image: url("../../../public/images/login-background.svg");
  animation-delay: -3s; 
}

.screen-org .slide:nth-child(3) {
  background-image: url("../../../public/images/reset-pw-bg.svg");
  animation-delay: -6s; 
}

.auth-logo-adjust{
  margin-top: 7%;
}

.screen-login {
  width: 58%;
  display: flex;
  flex-direction: column;
  /* background-color: #FFF; */
  /* background: url("../../../public/images/login-background.svg"); */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% 45vh;
  background-position: center;
  background-position-y: 20%;
  height: 82vh;
  overflow: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation: slideshow 10s ease infinite;
}

.login-custom-bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.screen-reset {
  width: 58%;
  display: flex;
  flex-direction: column;
  /* background-color: #FFF; */
  /* background: url("../../../public/images/reset-pw-bg.svg"); */
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-size: 100% 45vh;
  background-position: center;
  background-position-y: 20%;
  height: 82vh;
  overflow: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation: slideshow 10s ease infinite;
}

.screen-2 {
  width: 42%;
   height: 82vh;
  background-color: #FFF;
  overflow: auto;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom-carousel-container {
  width: 100%;
  height: 100%; /* Adjust the height based on your preference */
  overflow: hidden;
}

.custom-carousel {
  display: flex;
  width: 100%;
  height: 100%;
  transition: transform 1s ease-in-out; 
}


.image-container {
  flex: 0 0 100%;
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.5s ease-in-out; /* Optional: Add opacity transition for a fade effect */
}

.image-container.active {
  opacity: 1;
}

.theme-back-btn:hover {
  background-color: #ECF0FF;
}
.theme-back-btn {
  position: absolute;
  left: 18px;
  top: 34px;
  background-color: white;
  color : #121212;
  font-weight: 500;
  height: 33px;
  padding-inline: 10px;
  padding-block: 4px;
  border:1px solid #ECF0FF;
  border-radius: 6px;
  cursor: pointer !important;
}

.login-content-width{
  width: 75.1%;
}

.margin-1 {
  margin-top: 67px;
}

.org-logo {
  margin-top: 21px;
}

.org-logo img {
  width: 60px;
  height: 55px;
  margin-bottom: 24px;
  border-radius: 50%;
}

.login-title {
  font-size: 40px;
  margin: 0 0 0.5rem;
}

.org-btn {
  background: var(--theme-primary-color) !important;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 50px !important;
  padding: 10px 30px;
}

.org-btn:hover,
.theme-tabular-action-btn:hover {
  background-color: var(--theme-secondary-focus-color) !important;
}

.org-btn:disabled {
  opacity: 0.65;
  cursor: not-allowed;
  pointer-events: all;
}

.push-bottom {
  /* min-height: 40vh !important; */
  flex: 1;
}

.img-show {
  height: 80%;
  width: 80%;
  max-height: 530px;
  max-width: 450px;
  ;
}

form input[aria-autocomplete="list"] {
  border: none !important;
}

form input[type="date"] {
  /* border-radius: 10px !important; */
  border: 0.5px solid #000000 !important;
}

.password-toggle {
  float: right;
  position: absolute;
  top: 30.5%;
  right: 3%;
  cursor: pointer !important;
}

.mobile-input {
  position: relative;
  top: -31px;
  right: -8px;
}

#select-organization-form,
.responsive-condition {
  flex: 1 1 auto;
  justify-content: center;
}

.customer-support-box {
  flex: 0 1 40px;
}

.main-auth-content{
  display: flex; 
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  background-color: #15447C;
  margin-top: -2px;
  min-height: 17vh; 

}

.wave svg{
  display: block;
}

@media screen and (max-width:1480px) {
  .login-content-width{
    width: 80%;
  }
  
  
}

@media screen and (min-width:1400px) {
  .screen-org .slick-slide .default-img {
    height: 46vh;
  }
  
}



@media screen and (min-height:700px) {
  .screen-org{
    height: 75vh;
  }
  .screen-org .slick-slide .org-images-container {
    height: 75vh;
  }
  .screen-2{
    height: 75vh;
  }
 
}

@media screen and (min-height:1000px) {
  .screen-org{
    height: 73vh;
  }
  .screen-org .slick-slide .org-images-container {
    height: 73vh;
  }
  .screen-2{
    height: 73vh;
  }
 
}


@media screen and (max-width:980px) {

  #reset-password-form {
    max-width: 360px;
  }

  .screen-org,
  .screen-login,
  .screen-reset {
    display: none !important;
  }

  .screen-2 {
    width: 100vw;
    padding-top: 4%;
    border-radius: 10px;
  }

  form {
    width: auto !important
  }

  .screen-2>div {
    width: 80%;
  }

  form.initial-page {
    width: 100% !important
  }
  
}

@media screen and (max-width:550px) {
  .customer-support-box {
    flex-direction: column !important;
  }

  #submit-button {
    text-align: end;
  }

}

.keep-login label {
  color: #404040 !important;
}

.frgt {
  color: var(--theme-primary-color) !important;
  font-size: 12px !important;
  text-decoration: underline;
  cursor: pointer !important;
}

.theme-google-btn {
  outline: none;
  border: 1px solid rgb(226, 232, 240);
  background-color: white;
  border-radius: 6px;
  color: rgb(51, 65, 85);
  padding: 5px;
  padding-inline: 12px;
}
.theme-google-btn:hover {
  border: 1px solid black;
  color: black;
}

.theme-stu-login-text{
  color: #646060;
}

.theme-login{
  color: #15447C;
  text-decoration: underline;
}