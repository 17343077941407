  
  #content {
    padding-bottom: 50px;
  }
.timeline-section{
    scrollbar-width: none;
}
.timeline-section::-webkit-scrollbar {
    display: none;
  }
.timeline-date{
    padding: 1rem 1.5rem;
    margin-bottom: 0 !important;
}
.timeline .sub-timeline{
    padding-bottom: 0 !important;
    padding: 20px 39px;
    margin-bottom: 0 !important;
}
.sub-timeline{
    position: relative;
}
.sub-timeline-time{
    margin-top: -2.5rem;
}
.sub-timeline-icon{
    margin-top: -3rem;
} 
.sub-timeline-icon-top{
    position: absolute;
    height: 1rem;
    width: 1px;
    background-color: #DDDDDD;
    display: block;
    top: 0;
    left:9.2rem;
}
.sub-timeline-icon-bottom{
    position: absolute;
    height: 3.7rem;
    width: 1px;
    background-color: #DDDDDD;
    display: block;
    top: 3.5rem;
    left:9.2rem;
} 
.timeline-icon-div{
    margin-left: -0.5rem;
}
