.theme-tabs.theme-notification .nav-item .nav-link.active {
    
    border-radius: 0px;
    padding-block-end: 5px !important;
}


.navbar-light .navbar-nav.theme-tabs.theme-notification .nav-item .nav-link:hover {
    color: #0d0d0d !important;
    font-weight: bold !important;   
}

.time-text{
    color: #6a6a6a !important;
}

  .notification-tabs-view-all-container {
    position: relative !important;
  }
  
  .notification-view-all-container {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 8px;
    background-color: #fff;
    border-top: 1px solid #ccc; 
  
  }

  select.theme-input-control-white {
    height: 25.6px !important;
    width: fit-content !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    font-size: var(--theme-small-fs) !important;
    font-weight: 700;
    border-radius: 6px !important;
    border: 1px solid #fff !important;
    color: #fff !important; 
    line-height: 15px;
    background-color: transparent !important;
    background: url(../../../../public/images/arrow-white.svg) no-repeat 97.5% 2.8px !important;
    background-position-x: calc(100% - 8.5px) !important;
    background-position-y: 52% !important;
    background-size: 22px 22px !important;
  }
  select.form-select.theme-input-control-white:focus {
    border: 1px solid #fff !important;
    background-color : transparent !important;
    color : white !important;
    background: url(../../../../public/images/arrow-white.svg) no-repeat 97.5% 2.8px !important;
    background-position-x: calc(100% - 8.5px) !important;
    background-position-y: 52% !important;
    background-size: 22px 22px !important;
  }

  select.form-select.theme-input-control-white option {
    font-size: var(--theme-context-fs) !important;
    color: #000 !important; /* Set the color of the dropdown options */
  }

  .notifications-text-container{
    min-width: 29rem !important;
  }