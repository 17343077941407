:root {
  --theme-primary-color: #2b4378;
  --theme-secondary-light-color: #c6d2ed;
  --theme-secondary-color: #73809d;
  --theme-header-secondary-color: #555555;
  --theme-hover-color: #f7f9fb;
  --theme-hover-dark-color: #e3f2ff;
  --theme-focus-color: #2574cf;
  --theme-secondary-focus-color: #2a5bc7;
  --theme-dark-color: #121212;

  --theme-gray-color: #5a5a5a;
  --theme-gray-light-color: #f5f5f8;

  --theme-danger-hover-color: #ff0000;
  --theme-danger-btn-color: #d3151532;

  --theme-danger-color: #dc1c1c;
  --theme-danger-bg-color: #FCE9E9;
  --theme-success-color: #10a83b;
  --theme-warning-color: #eba214;
  --theme-disabled-color: #bbbbbb;

  --theme-body-background: #f1f5f7;

  --theme-login-secondary-color: #8a8a8a;

  --theme-mini-fs: 10px;
  --theme-small-fs: 12px;
  --theme-context-fs: 14px;
  --theme-header-fs: 16px;
  --theme-heading-fs: 18px;
  --theme-large-fs: 24px;
  --theme-larger-fs: 32px;
  --theme-table-fs: 13px;
}

body,
#root,
.app {
  min-height: 100vh;
  min-height: 100svh;
}

hr {
  background-color: var(--theme-disabled-color);
  border-color: var(--theme-disabled-color);
}

.app {
  display: flex;
  flex-direction: column;
}

/* COLORS */

.theme-primary-background {
  background: var(--theme-primary-color) !important;
}

.theme-primary-color {
  color: var(--theme-primary-color) !important;
}

.theme-focus-color {
  color: var(--theme-primary-color) !important;
}

.theme-secondary-color {
  color: var(--theme-secondary-color) !important;
}

.theme-secondary-light-color {
  color: var(--theme-secondary-light-color) !important;
}

.theme-header-secondary-color {
  color: var(--theme-header-secondary-color) !important;
}

.theme-dark-color {
  color: var(--theme-dark-color) !important;
}

.theme-gray-color {
  color: var(--theme-gray-color) !important;
}

.theme-danger-color {
  color: var(--theme-danger-color) !important;
}
.theme-danger-bg-color {
  color: var(--theme-danger-bg-color) !important;
}

.theme-success-color {
  color: var(--theme-success-color) !important;
}

.theme-disabled-color {
  color: var(--theme-disabled-color) !important;
}

.theme-active-inactive-status-button {
  background: var(--white-100, #fff);
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 4px var(--4, 4px);
  transition: border-color 0.3s;
  border-radius: 6px;
}

.theme-regular-status {
  background: rgba(34, 73, 159, 0.1);
  text-align: center;
  border-radius: 9px;
  color: rgb(34, 73, 159);
  position: relative;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 9px;
}

.theme-approved-status {
  background: rgba(16, 168, 59, 0.1);
  text-align: center;
  border-radius: 9px;
  color: rgba(16, 168, 59);
  position: relative;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 9px;
}

.theme-pending-status {
  background: rgba(18, 18, 18, 0.1);
  text-align: center;
  border-radius: 9px;
  color: rgba(18, 18, 18);
  position: relative;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 9px;
}

.theme-rejected-status {
  background: rgba(220, 28, 28, 0.1);
  text-align: center;
  border-radius: 9px;
  color: rgba(220, 28, 28);
  position: relative;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 9px;
}

.theme-waiting-status {
  background: rgba(235, 162, 20, 0.1);
  text-align: center;
  border-radius: 9px;
  color: rgb(235, 162, 20);
  position: relative;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 9px;
}

.theme-login-secondary-color {
  color: var(--theme-login-secondary-color) !important;
}

.theme-secondary-focus-color {
  color: var(--theme-secondary-focus-color) !important;
}

.theme-body-background {
  background: var(--theme-body-background) !important;
}

.theme-success-banner {
  background: #5cb85c !important;
  color: #fff !important;
  font-weight: 700 !important;
  min-width: 65.1333px !important;
  border-radius: 3px !important;
  padding-block: 1.15px;
  padding-inline: 3px;
  text-align: center;
}

.theme-danger-banner {
  background: #d9534f !important;
  color: #fff !important;
  font-weight: 700 !important;
  min-width: 65.1333px !important;
  border-radius: 3px !important;
  padding-block: 1.15px;
  padding-inline: 3px;
  text-align: center;
}

.theme-pending-banner {
  background: #f0ad4e !important;
  color: #fff !important;
  font-weight: 700 !important;
  min-width: 65.1333px !important;
  border-radius: 3px !important;
  padding-block: 1.15px;
  padding-inline: 3px;
  text-align: center;
}

.theme-secondary-banner {
  background: #008cba !important;
  color: #fff !important;
  font-weight: 700 !important;
  min-width: 65.1333px !important;
  border-radius: 3px !important;
  padding-block: 1.15px;
  text-align: center;
}

.theme-tag-banner {
  background: #ebecf1 !important;
  color: #121212 !important;
  min-width: 65.1333px !important;
  border-radius: 3px !important;
  padding-block: 1.15px;
  padding-inline: 3px;
  text-align: center;
}

/* FONT SIZES */

.fs-text {
  font-size: var(--theme-context-fs) !important;
}

.fs-heading {
  font-size: var(--theme-heading-fs) !important;
}

.fs-header {
  font-size: var(--theme-header-fs) !important;
}

.fs-large {
  font-size: var(--theme-large-fs) !important;
}

.fs-small {
  font-size: var(--theme-small-fs) !important;
}

.fs-mini {
  font-size: var(--theme-mini-fs) !important;
}

.fs-larger {
  font-size: var(--theme-larger-fs) !important;
}

/* UTILITY CLASSES */

.white-space-pre-line {
  white-space: pre-line !important;
}

.hide {
  visibility: hidden !important;
}

.rounded {
  border-radius: 100vh !important;
}

.sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.sticky-left {
  position: sticky;
  left: 0;
  background-color: #fff;
}

.paragraph {
  text-indent: 13%;
}

.warning-indicator {
  width: 8px;
  height: 8px;
  border-radius: 100vh;
  background-color: #b2b521;
}

select.form-select.width-filter,
input.form-control.width-filter {
  width: fit-content !important;
  padding-right: 2rem !important;
}

div.width-filter {
  width: fit-content !important;
}

input[type="date"].form-control.width-filter {
  padding-right: 4% !important;
}

select.form-select.height-filter,
input.form-control.height-filter {
  height: 30px !important;
}

select.form-select.height-filter-modal,
input.form-control.height-filter-modal {
  height: 32px !important;
}

.ms-16 {
  margin-inline-start: 16px !important;
}

.me-16 {
  margin-inline-end: 16px !important;
}

.mb-16 {
  margin-block-end: 16px !important;
}

.mt-16 {
  margin-block-start: 16px !important;
}

.me-20 {
  margin-inline-end: 20px !important;
}

.ps-16 {
  padding-inline-start: 16px !important;
}

.pe-16 {
  padding-inline-end: 16px !important;
}

.pb-16 {
  padding-block-end: 16px !important;
}

.pt-16 {
  padding-block-start: 16px !important;
}

.w-fit {
  width: fit-content !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-16 {
  gap: 16px !important;
}

.width-50-p {
  width: 50% !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #ddd !important;
}

.border-right-gray {
  border-right: 1px solid #ddd;
}

@media screen and (max-width: 768px) {
  .border-right-gray {
    border-bottom: 1px solid #ddd;
  }
}

.allow-wrap {
  white-space: normal !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.flex-1 {
  flex: 1 !important;
}

.link-hover:hover {
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
  cursor: pointer !important;
  color: var(--theme-focus-color) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.disable-actions {
  pointer-events: none !important;
}

.rotate-img-180 img {
  transform: rotate(-180deg);
  transition: transform 0.12s ease-in;
}

.rotate-img-90 img {
  transform: rotate(90deg);
  transition: transform 0.12s ease-in;
}

.rotate-img-270 img {
  transform: rotate(270deg);
  transition: transform 0.12s ease-in;
}

.rotate-img-0 img {
  transform: rotate(0deg);
  transition: transform 0.12s ease-out;
}

.invalid-field {
  display: block;
  color: #dc3545;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

/* Set Modal Height for scroll */

.modal-set-height .modal-body {
  padding-block-end: 0;
  height: min(60vh, 40rem) !important;
  overflow: scroll;
}

/* Sticky Modal */

.modal-set-height .modal-body .sticky-container {
  padding-block: 16px;
  background-color: #fff;
  position: sticky !important;
  bottom: -2px;
  border-bottom-left-radius: 20px;
  z-index: 10;
}

/* Text Area floatting */
@media only screen and (min-width: 768px) {
  .floating-textarea {
    position: absolute !important;
  }
}

/* ScrollBar */

.scroll-visible::-webkit-scrollbar {
  display: initial !important;
  scroll-behavior: smooth;
}

.scroll-visible::-webkit-scrollbar-thumb {
  background-color: var(--theme-primary-color);
  border-radius: 5px;
}

.scroll-visible::-webkit-scrollbar-thumb:hover {
  background-color: var(--theme-secondary-focus-color);
}

.scroll-visible::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll-visible {
  overflow: auto;
  scroll-behavior: smooth;
}

.scroll-bar-visible {
  overflow: auto;
  scrollbar-color: var(--theme-primary-color) transparent;
  /* scrollbar-color: var(--theme-primary-color) var(--theme-secondary-light-color); */
  scrollbar-width: thin;
}

.btn-80-w {
  width: 80% !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

/*  --UTILITY CLASS END--  */

/* TABLE UTILITY CLASSES */

/* Border right or left */

:is(.modal, .theme-content-container) .theme-content-table tbody td.table-border-left-col {
  border-left: 1px solid #dddddd !important;
}

:is(.modal, .theme-content-container) .theme-content-table tbody td.table-border-right-col {
  border-right: 1px solid #dddddd !important;
}

/* Sticky Scroll for Tables */

.scrollable-table-container {
  max-height: 80vh !important;
  min-height: 200px;
  position: relative;
  overflow-y: auto;
  scrollbar-color: var(--theme-primary-color) transparent;
  scrollbar-width: thin;
}

.scrollable-table-container thead.sticky-table-head {
  position: sticky !important;
  top: 0 !important;
}

.scrollable-table-container thead.sticky-table-head th {
  position: relative;
}

.scrollable-table-container thead.sticky-table-head th::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: -2px;
  right: 0;
  left: 0;
  border-bottom: 1px solid #bdbaba;
}

/* Table cell alignment */

/* Align content in table cell in the center */
.theme-content-table.table-hover td.cell-align-vertically {
  line-height: initial !important;
  vertical-align: middle;
}

.theme-content-table.table-hover td.cell-align-horizontally {
  line-height: initial !important;
  text-align: center;
}

/* both vertical and horizontal */
.theme-content-table.table-hover td.cell-align-center {
  line-height: initial !important;
  vertical-align: middle;
  text-align: center;
}

/* Table Row Highlighter */
.theme-content-table.table-hover tr.highlight {
  background-color: #fafcdb;
}

/* HEADER CSS */

.h-dwn {
  width: 140px;
}

.h-dwn .form-select {
  max-height: 32px;
}

select.theme-input-control-light.form-select {
  width: 116px;
}

.subheader-dropdown {
  /* border: 1px solid #FFF; */
  padding: 2px 0px !important;
  border-radius: 6px;
}

.cnavbar .dropdown-menu[data-bs-popper] .dropdown-item.profile-dwn-end {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-bottom: 1px solid #d6d6d6;
}

.navbar .form-control {
  height: 32px;
  border-radius: 20px;
  width: 333px;
  background: #fff;
  border: 0px;
  color: #092c44;
  border: 1px solid #000000;
}

.search-control {
  background: #fff url(../public/images/search.svg) no-repeat 96% 5px !important;
}

.theme-input-control.academic-year-header-light {
  border: 1px solid rgb(221, 221, 221) !important;
  margin-bottom: 0px !important;
}

.header-controls {
  width: min(100%, 1000px);
}

.search-box-btns {
  position: sticky;
  background-color: #fff;
  padding-block: 20px;
  bottom: 0%;
}

.search-box-btns .theme-action-btn {
  min-width: 113.117px !important;
}

.search-route {
  padding-block: 8px;
  padding-inline: 8px;
  color: var(--theme-dark-color);
}

.search-route:hover {
  background-color: var(--theme-hover-color);
  border-radius: 5px;
  color: var(--theme-primary-color);
  padding-inline: 1.5rem;
  transition: 0.08s all ease-out;
  font-weight: 600;
}

.sub-header-list .navbar-expand-lg .navbar-nav {
  flex-wrap: wrap;
}

.header-icon {
  background-color: white !important;
}

.header-icon:hover {
  border-radius: 6px;
  background-color: var(--theme-hover-dark-color) !important;
}

@media screen and (max-width: 765px) {
  .h-dwn-header-container {
    margin-inline-start: 0px !important;
  }

  .cnavbar .search-box-dropdown.dropdown-menu[data-bs-popper] {
    width: 100% !important;
  }

  .heading-container .header-controls .form-control.search-control {
    width: 100%;
  }

  .heading-container .header-controls .position-relative {
    width: 100%;
  }

  .heading-container .header-controls .position-relative #dropdown-basic {
    width: 100%;
  }
}

@media screen and (max-width: 1430px) {
  .header-controls {
    width: auto;
  }

  .header-controls .current-year-control {
    margin-inline-end: 0 !important;
  }
}

@media screen and (max-width: 522px) {
  .h-dwn-header-container {
    margin-inline-start: auto !important;
    margin-inline-end: 0px !important;
  }
}

/* POPOVERS, NAVBAR, DROPWDOWNS STYLINGS */

.collapse:not(.show) {
  display: flex !important;
}

.org-banner {
  cursor: pointer !important;
}

.dropdown-toggle::after {
  display: none !important;
}

button.dropdown-toggle:focus,
.btn-check:focus+.btn-primary,
.btn-primary:focus {
  box-shadow: none;
}

.dropdown-menu.show .dropdown-item {
  color: var(--theme-dark-color) !important;
}

.dropdown-menu.show .dropdown-item:hover,
.dropdown-menu.show .dropdown-item:focus {
  color: var(--theme-primary-color) !important;
  background-color: var(--theme-hover-color) !important;
  border-radius: 6px !important;
  transition: 0.08s background-color ease-out !important;
}

.header-profile-menu.dropdown-menu[data-bs-popper] {
  top: 45px;
  padding: 0 !important;
  border-radius: 4px !important;
  /* box-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  /* border-color: var(--theme-secondary-color); */
  border: none !important;
}

/* .header-profile-menu:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #111;
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
} */

.header-profile-menu.dropdown-menu[data-bs-popper] .dropdown-item {
  padding-block: 6px !important;
}

.subheader-dropdown {
  margin-block: 5px !important;
}

.subheader-dropdown .dropdown-menu.show {
  /* border-color: var(--theme-secondary-color); */
  border: none;
  width: 203px;
  padding: 0 I !important;
  padding-block: 5px !important;
  inset: 8px auto auto -80px !important;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.cnavbar .search-box-dropdown.dropdown-menu[data-bs-popper] {
  width: 333px !important;
  max-height: 27.5rem !important;
  overflow: auto;
  top: auto !important;
  left: 0 !important;
  padding-bottom: 0;
  border-radius: 6px !important;
}

.show.dropdown .rotate-180 {
  transform: rotate(-180deg);
  transition: transform 0.1s ease-out;
}

.dropdown:not(.show) .rotate-180 {
  transform: rotate(0deg);
  transition: transform 0.1s ease-in;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.tab-sec .navbar-nav a {
  font-size: 16px;
  background: var(--theme-primary-color) !important;
  border-radius: 2px;
  margin-right: 10px;
}

.tab-sec .navbar-nav a.dropdown-item {
  background: #fff !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 7px !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff !important;
  border-radius: 10px;
  font-weight: 500 !important;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff !important;
  font-weight: bold !important;
}

.popover-body {
  height: 500px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  overflow: auto;
  padding: 5px 0;
}

ul.list-dropdown-custom.p-0.pb-2 {
  padding: 5px 10px !important;
}

ul.list-dropdown-custom {
  list-style: none;
  float: left;
  width: 100%;
  margin: 0;
  display: flex;
  flex-flow: wrap;
}

ul.list-dropdown-custom li {
  list-style: none;
  float: left;
  width: 33.33%;
  text-align: left;
  padding: 9px 10px;
  color: #171717;
  font-size: 20px;
}

.popover {
  border-radius: 4px;
  font-family: "Lato", "Roboto", serif !important;
  max-width: 712px;
  width: 712px;
  inset: 12px auto auto -248px !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px !important;
}

.popover .popover-arrow {
  inset: -7px auto auto 35% !important;
}

.sub-header-list .navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.noti-circle {
  padding-inline: 2px;
  min-width: 16.925px;
  height: 17px;
  border-radius: 1000vh;
  color: white;
  font-size: 12px;
  font-weight: bold;
  background-color: red;
  position: absolute;
  top: -20%;
  right: -30%;
}

.notifications-dropdown-menu.show {
  display: flex;
  flex-direction: column;
}

.notifications-dropdown-menu {
  width: 33rem;
  height: 31.5rem;
  overflow-y: auto;
  line-height: 1.25rem;
  font-size: 15px;
  top: 42.6px !important;
  left: inherit !important;
  right: -2px !important;
  border: none;
  border-radius: 6px !important;
  padding-block-end: 0;
}

.main-footer {
  width: 100% !important;
  margin-top: auto;
  bottom: 0 !important;
}

ul.list-dropdown-custom li:hover {
  border-radius: 4px !important;
  background: #ecf0ff;
  cursor: pointer !important;
}

ul.list-dropdown-custom li.menu-hover-organization:hover {
  background: #ecf0ff !important;
}

ul.list-dropdown-custom li.menu-hover-finance:hover {
  background: #f2ffec !important;
}

ul.list-dropdown-custom li.menu-hover-admissions:hover {
  background: #ecfffa !important;
}

ul.list-dropdown-custom li.menu-hover-hr:hover {
  background: #ffecec !important;
}

ul.list-dropdown-custom li.menu-hover-communications:hover {
  background: #f7ebde !important;
}

ul.list-dropdown-custom li.menu-hover-Attendance:hover {
  background: #f8ecff !important;
}

ul.list-dropdown-custom li.menu-hover-student_info:hover {
  background: #ecf5ff !important;
}

ul.list-dropdown-custom li.menu-hover-certificates:hover {
  background: #fcfcd8 !important;
}

ul.list-dropdown-custom li.menu-hover-academics:hover {
  background: #f2ffec !important;
}

ul.list-dropdown-custom li.menu-hover-expense_management:hover {
  background: #ffecff !important;
}

ul.list-dropdown-custom li.menu-hover-exams:hover {
  background: #f7ecff !important;
}

ul.list-dropdown-custom li.menu-hover-inventory:hover {
  background: #ecfffa !important;
}

ul.list-dropdown-custom li.menu-hover-transportmanagement:hover {
  background: #fff6ec !important;
}

ul.list-dropdown-custom li.menu-hover-visitor_management:hover {
  background: #fcfcd8 !important;
}

ul.list-dropdown-custom li.menu-hover-concerns_feedbacks:hover {
  background: #f2ffec !important;
}

ul.list-dropdown-custom li.menu-hover-hr_pro:hover {
  background: #ecf0ff !important;
}

ul.list-dropdown-custom li.menu-hover-hostel_management:hover {
  background: #feffe0 !important;
}

ul.list-dropdown-custom li.menu-hover-social_post:hover {
  background: #eaf1ff !important;
}

ul.list-dropdown-custom li.menu-hover-online_class:hover {
  background: #f2ffec !important;
}

ul.list-dropdown-custom li.menu-hover-assessments:hover {
  background: #f8ecff !important;
}

ul.list-dropdown-custom li.menu-hover-lesson_delivery:hover {
  background: #ecfffa !important;
}

/* 

.notification-link {
  text-decoration: underline;
}

@media screen and (max-width: 1256px) {
  .subheader-dropdown .dropdown-menu.show {
    width: auto;
    inset: 4px auto auto -74px !important;
  }

  #popover-contained.popover {
    inset: 12px auto auto 0px !important;
  }

  .popover .popover-arrow {
    inset: -7px auto auto 0px !important;
  }
}

.custom-select {
  background-color: #f8f9fa;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* TABS */

.theme-tabs {
  background-color: #fff !important;
  min-height: 36px !important;
  border-block-end: 1px solid #dddddd !important;
}

.theme-tabs .nav-item {
  height: 35px !important;
  border-left-color: transparent !important;
  margin-inline-end: 4px !important;
}

.theme-tabs .nav-item:hover {
  background-color: var(--theme-hover-color) !important;
  border-radius: 6px !important;
}

.theme-tabs .nav-item .nav-link,
.theme-tabs .nav-item .nav-link.active {
  padding: 0 !important;
  padding-block-start: 10.5px !important;
  padding-block-end: 3px !important;
  margin-block-end: 4px !important;
  margin-inline: 8px !important;
}

.theme-tabs .nav-item .nav-link {
  color: #00000099 !important;
  font-weight: 400 !important;
  font-size: var(--theme-context-fs) !important;
}

.theme-tabs .nav-item .nav-link.active {
  background-color: transparent !important;
  color: #1c5ce9 !important;
  font-weight: 600 !important;
  font-size: var(--theme-context-fs) !important;
  height: fit-content !important;
  border-block-end: 2px solid #1c5ce9 !important;
}

.theme-tabs+.tab-content {
  width: 100% !important;
  background: #fff !important;
  border-radius: 0px !important;
  padding: 0px !important;
}

.notification.show.dropdown>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

/* TOOLTIPS */

.theme-tooltip>.tooltip-inner {
  /* min-width: none !important; */
  max-width: 396px !important;
  /* padding-inline: 13px !important;
  padding-block: 9px !important; */
  /* line-height: 14px; */
  font-family: "Lato", "Roboto", serif;
  font-weight: 400;
  font-size: 12px !important;
  white-space: pre-wrap;
  background: var(--theme-dark-color);
  color: #ffff;
}

/* PROGRESS TABS */

.theme-progress-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
}

.theme-progress-aside {
  display: flex;
  margin-inline-end: 10px;
  flex: 1;
  margin-top: 7px;
}

@media (min-width: 1500px) {
  .theme-progress-container {
    flex-direction: row;
  }

  .theme-progress-aside {
    margin-top: 7px;
    margin-inline-end: 16px;
  }
}

.theme-progress-tabs {
  padding-inline-start: 22px;
  /* width: 100% !important; */
  display: flex;
  gap: min(170px, 18.5vw) !important;
  border: none !important;
}

.theme-progress-tabs .nav-item .nav-link {
  background-color: #fff;
  border: 0 !important;
}

.theme-progress-tabs .nav-item {
  border: none !important;
  background-color: transparent !important;
  position: relative;
}

.theme-progress-tabs .nav-item button {
  padding-inline: 8px !important;
}

.theme-progress-tabs p {
  width: 94px;
  white-space: nowrap;
  color: var(--theme-dark-color) !important;
  font-size: var(--theme-small-fs) !important;
  text-align: center;
  position: absolute;
  left: -26px;
  top: 40px;
}

.theme-progress-tabs .nav-item:not(:last-child)::after {
  content: "";
  position: absolute;
  border-top: 2px solid var(--theme-disabled-color);
  left: 35px;
  width: min(18.5vw, 170px);
  top: 18px;
}

.theme-progress-tabs+.tab-content {
  display: none !important;
  border: none !important;
}

/* MODAL STRUCTURE */

.modal-header {
  font-size: var(--theme-header-fs) !important;
  padding-inline: 16px !important;
  padding-block-start: 16px !important;
  padding-block-end: 8px !important;
}

.modal-header .modal-title {
  font-size: var(--theme-header-fs) !important;
  color: var(--theme-dark-color) !important;
  font-weight: 400 !important;
}

.modal-header .btn-close {
  background: url("../public/images/modal-close.svg") no-repeat 30% 10px !important;
  padding-inline: 0px;
  margin-inline-end: 1px;
}

.modal-header .btn-close:focus {
  box-shadow: none !important;
}

.modal-body {
  padding: 16px;
  font-size: var(--theme-small-fs) !important;
}

.modal-body form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-backdrop {
  background-color: #d9d9d9;
  opacity: 0.4 !important;
}

:is(.theme-modal-md, .theme-modal-lg) .modal-body {
  display: flex !important;
}

/* CONFIRMATION MODAL */

.theme-confirmation-modal .modal-dialog {
  max-width: 322px !important;
}

.theme-confirmation-modal .modal-content {
  min-height: 160px !important;
  border: 1px solid var(--theme-secondary-color) !important;
}

.theme-confirmation-modal .modal-body {
  margin-block-start: 8px !important;
  text-align: center !important;
  font-size: var(--theme-small-fs) !important;
}

.theme-confirmation-modal .modal-body :is(h1, h2, h3, h4, h5, h6) {
  font-size: var(--theme-large-fs) !important;
  margin-block-end: 10px !important;
  color: var(--theme-warning-color);
}

/* MODAL CONTENT CONTAINER */

/* for getting same structure as main content inside modal */
.theme-content-container.theme-modal-content-container {
  width: 100% !important;
  margin-inline: auto !important;
  margin-block-end: 0px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  min-height: auto;
}

/* MODAL MEDIUM */

.theme-modal-md .modal-dialog {
  max-width: 874px;
  margin-inline: auto !important;
}

.theme-modal-md .modal-content {
  min-height: 251px !important;
  border: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* MODAL LARGE */

.theme-modal-lg .modal-dialog {
  max-width: 1280px !important;
}

.theme-modal-lg .modal-content {
  min-height: 258px !important;
  border: 0 !important;
}

/* MODAL SMALL */

.theme-modal-sm .modal-dialog {
  max-width: 301px !important;
}

/* MODAL MEDIUM SMALL */

.theme-modal-ml .modal-dialog {
  max-width: 602px !important;
}

.theme-confirmation-modal .modal-content {
  min-height: 160px !important;
  /* border: 1px solid var(--theme-secondary-color) !important; */
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.theme-confirmation-modal .modal-body {
  margin-block-start: 8px !important;
  text-align: center !important;
  font-size: var(--theme-small-fs) !important;
}

.theme-confirmation-modal .modal-body :is(h1, h2, h3, h4, h5, h6) {
  font-size: var(--theme-large-fs) !important;
  margin-block-end: 10px !important;
  color: #eba214;
}

/* PLACEHOLDERS */

input::placeholder {
  font-size: var(--theme-context-fs) !important;
}

input:-ms-input-placeholder {
  font-size: var(--theme-context-fs) !important;
}

input::-ms-input-placeholder {
  font-size: var(--theme-context-fs) !important;
}

:is(.theme-input-control, .theme-input-control-modal)::placeholder {
  color: #73809d !important;
  font-size: var(--theme-small-fs) !important;
}

:is(.theme-input-control, .theme-input-control-modal):-ms-input-placeholder {
  color: #73809d !important;
  font-size: var(--theme-small-fs) !important;
}

:is(.theme-input-control, .theme-input-control-modal)::-ms-input-placeholder {
  color: #73809d !important;
  font-size: var(--theme-small-fs) !important;
}

:is(input:focus, textarea:focus)::placeholder {
  color: var(--theme-dark-color) !important;
}

:is(input:focus, textarea:focus):-ms-input-placeholder {
  color: var(--theme-dark-color) !important;
}

:is(input:focus, textarea:focus)::-ms-input-placeholder {
  color: var(--theme-dark-color) !important;
}

/* ERROR PLACEHOLDER/TABLE PLACEHOLDER */

:is(input.is-invalid, input.is-invalid:focus)::placeholder {
  color: var(--theme-danger-color) !important;
}

:is(input.is-invalid, input.is-invalid:focus):-ms-input-placeholder {
  color: var(--theme-danger-color) !important;
}

:is(input.is-invalid, input.is-invalid:focus)::-ms-input-placeholder {
  color: var(--theme-danger-color) !important;
}

.placeholder-glow:nth-of-type(1) {
  border-bottom: 1px solid #888888;
  padding-block: 11px;
}

.placeholder-glow {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-block: 0;
  padding-block: 4px;
  min-height: 6vh !important;
}

.placeholder-glow .placeholder {
  background-color: #888;
  flex-grow: 1;
}

.placeholder-glow:hover {
  background-color: var(--theme-hover-color) !important;
}

.placeholder-glow button.placeholder.theme-tabular-action-btn:disabled {
  background-color: var(--theme-primary-color) !important;
  height: 25px !important;
  margin-block-end: 7px;
}

.ai-placeholder {
  flex-grow: 1;
}

.ai-placeholder .placeholder-glow {
  min-height: auto !important;
  padding-block: 0.5rem;
  border-bottom: none;
}

.ai-placeholder .placeholder-glow:hover {
  background-color: transparent !important;
}

.ai-placeholder .placeholder-glow .placeholder {
  border-radius: 10px;
  background: linear-gradient(to bottom, rgba(253, 135, 172, 0.5), rgba(152, 60, 236, 0.3));
}

.ai-placeholder .placeholder-glow .placeholder.ai-placeholder__piece-1 {
  background: linear-gradient(to bottom, rgba(253, 135, 172, 0.5), rgba(152, 60, 236, 0.5));
}
.ai-placeholder .placeholder-glow .placeholder.ai-placeholder__piece-2 {
  max-width: 75%;
}
.ai-placeholder .placeholder-glow .placeholder.ai-placeholder__piece-3 {
  max-width: 50%;
}

/* ERROR PLACEHOLDER/TABLE PLACEHOLDER END */

/* FORM STRUCTURE */

label.form-label {
  color: var(--theme-secondary-color);
  font-size: var(--theme-small-fs);
}

.form-check label {
  font-size: var(--theme-small-fs);
  margin-inline-start: 10px !important;
}

.form-check-input {
  width: 18px !important;
  height: 18px !important;
  margin-top: 0 !important;
}

.form-check-input:disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
  background-color: var(--theme-disabled-color) !important;
}

.form-check-input:disabled:hover {
  opacity: 0.5 !important;
  background-color: var(--theme-disabled-color) !important;
}

.form-check-input:disabled:checked:hover {
  background-color: var(theme-secondary-focus-color) !important;
}

input[type="password"].was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

input[type="checkbox"]:hover {
  box-shadow: none !important;
  background-color: #e5e5e5 !important;
}

input[type="checkbox"]:focus {
  box-shadow: none !important;
  border-color: var(--theme-primary-color) !important;
}

input[type="checkbox"]:checked {
  border-radius: 4px !important;
  border-color: var(--theme-secondary-focus-color) !important;
  background-color: var(--theme-secondary-focus-color) !important;
}

/* FILE INPUT START */

.file-input-label,
.file-input-label-modal {
  width: 100%;
  display: flex;
  align-items: center;
  padding-inline-start: 5px;
  cursor: pointer;
}

.file-input-label span:first-child,
.file-input-label-modal span:first-child {
  flex: 1;
  overflow: hidden;
  /* Ensures ellipsis applies to the text */
  white-space: nowrap;
  /* Prevents wrapping */
  text-overflow: ellipsis;
  /* Shows ellipsis when text is too long */
}

.file-input-label,
.file-input-label-modal .browse-button {
  flex-basis: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin-inline-start: auto;
  padding-inline: 5px;
  background-color: #2a5bc71a !important;
  border-left: 1px solid #73809d;
}

input[type="file"].theme-file-select,
.file-input-label {
  height: 30px;
  border: 1px solid #73809d;
  border-radius: 4px;
}

input[type="file"].theme-file-select-modal,
.file-input-label-modal {
  height: 32px;
  border: 1px solid #73809d;
  border-radius: 4px;
}

input[type="file"].theme-file-select-modal.form-control::file-selector-button,
input[type="file"].theme-file-select.form-control::file-selector-button {
  background-color: #2a5bc71a !important;
}

/* FILE INPUT END */

form input[type="date"],
input[type="date"] {
  border: 1px solid var(--theme-secondary-color) !important;
}

textarea.form-control {
  resize: none;
}

select.form-select {
  appearance: none !important;
}

select.form-select:disabled,
input.form-control:disabled,
textarea.form-control:disabled {
  cursor: not-allowed;
  background-color: #f5f5f5 !important;
  border-color: var(--theme-disabled-color) !important;
  color: #bbbbbb;
}

.form-control[readonly] {
  cursor: auto;
  background-color: #fdfeff !important;
  border-color: var(--theme-disabled-color) !important;
  color: var(--theme-dark-color);
}

select.form-select:focus {
  box-shadow: 0.2px 0.2px 2px var(--theme-secondary-focus-color);
}

.form-control:focus,
.form-select:focus {
  appearance: auto;
  background: #fff;
  box-shadow: none;
  border: 1px solid var(--theme-dark-color) !important;
}

textarea:focus,
label.form-label:focus-within {
  color: var(--theme-dark-color) !important;
  box-shadow: 0.1px 0.1px 2px rgb(68, 68, 68);
  background-color: white !important;
}

input[type="date"].form-control:invalid,
input[type="date"].form-control.is-invalid {
  background-image: none !important;
}

input.form-control.is-invalid:not([multiple]):not([size]),
textarea.form-control.is-invalid:not([multiple]):not([size]) {
  color: var(--theme-danger-color) !important;
  border: 1px solid var(--theme-danger-color) !important;
}

input.form-control.is-invalid:not([multiple]):not([size]):focus textarea.form-control.is-invalid:not([multiple]):not([size]):focus {
  color: var(--theme-danger-color) !important;
  box-shadow: none !important;
}

.form-select.is-invalid:not([multiple]):not([size]) {
  background-image: url(../public/images/danger-down-arrow.svg) !important;
  background-position-x: calc(100% - 8.5px) !important;
  background-position-y: 70% !important;
  background-size: 22px 22px !important;
  color: var(--theme-danger-color) !important;
  border: 1px solid var(--theme-danger-color) !important;
}

.form-select.is-invalid:not([multiple]):not([size]):focus {
  background-image: url(../public/images/danger-down-arrow.svg) !important;
  background-position-x: calc(100% - 8.5px) !important;
  background-position-y: 70% !important;
  background-size: 22px 22px !important;
  color: var(--theme-danger-color) !important;
  box-shadow: none !important;
}

.form-select,
.form-select.theme-input-control.theme-input-control-modal {
  background-image: url(../public/images/down-arrow-false.svg);
  background-position-x: calc(100% - 8.5px) !important;
  background-position-y: 60% !important;
  background-size: 22px 22px !important;
}

.form-select:focus,
.form-select.theme-input-control.theme-input-control-modal:focus {
  background: url(../public/images/down-arrow.svg) no-repeat 97.5% 2.8px !important;
  background-color: white !important;
  background-position-x: calc(100% - 8.5px) !important;
  background-position-y: 60% !important;
  background-size: 22px 22px !important;
  color: var(--theme-dark-color) !important;
}

.form-select.theme-input-control {
  background-image: url(../public/images/down-arrow-false.svg);
  background-position-x: calc(100% - 5.5px) !important;
  background-position-y: 65% !important;
  background-size: 22px 22px !important;
}

.form-select.theme-input-control:focus {
  background: url(../public/images/down-arrow.svg) no-repeat 97.5% 2.8px !important;
  background-color: white !important;
  background-position-x: calc(100% - 5.5px) !important;
  background-position-y: 65% !important;
  background-size: 22px 22px !important;
}

.login-input-control {
  height: 50px !important;
  background-color: #fff !important;
  border: 1px solid #8a8a8a !important;
  border-radius: 7px !important;
  font-size: 14px !important;
  color: var(--theme-login-secondary-color) !important;
}

.form-select.login-input-control {
  background-image: url(../public/images/down-arrow-false.svg);
  background-position-x: calc(100% - 8.5px) !important;
  background-position-y: 52% !important;
  background-size: 22px 22px !important;
}

.form-select.login-input-control:focus {
  background: url(../public/images/down-arrow.svg) no-repeat 97.5% 2.8px !important;
  background-color: white !important;
  background-position-x: calc(100% - 8.5px) !important;
  background-position-y: 52% !important;
  background-size: 22px 22px !important;
  color: var(--theme-dark-color) !important;
}

.theme-input-control-light {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: var(--theme-context-fs) !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  border-radius: 6px !important;
  border: 1px solid #dddddd !important;
  color: #888888 !important;
}

.theme-input-control:not(textarea) {
  height: 30px !important;
}

.theme-input-control-modal:not(textarea) {
  height: 32px !important;
}

.theme-input-control {
  padding-inline: 8px;
  color: var(--theme-secondary-color) !important;
  font-size: var(--theme-small-fs) !important;
  border: 1px solid var(--theme-secondary-color) !important;
  border-radius: 4px !important;
  font-weight: 400 !important;
}

.theme-input-control-light:focus,
.theme-input-control:focus,
.theme-input-control-modal:focus {
  color: #121212 !important;
}

/* Filled Input Controls */
:is(input, select, textarea).filled-field:not(:placeholder-shown) {
  color: var(--theme-secondary-focus-color) !important;
  border-color: var(--theme-secondary-focus-color) !important;
}

:is(input, select, textarea).filled-field:not(:placeholder-shown):focus {
  color: #121212 !important;
  border-color: var(--theme-dark-color) !important;
}

div.input-filled> :is(input, select, textarea) {
  color: var(--theme-secondary-focus-color) !important;
  border-color: var(--theme-secondary-focus-color) !important;
}

div.input-filled>.form-select,
div.input-filled>.form-select.theme-input-control.theme-input-control-modal {
  background-image: url(../public/images/arrow-blue.svg);
}

div.input-filled> :is(input, select):disabled {
  color: var(--theme-header-secondary-color) !important;
  border-color: var(--theme-disabled-color) !important;
}

div.input-filled>.form-select:disabled,
div.input-filled>.form-select.theme-input-control.theme-input-control-modal:disabled {
  background-image: url(../public/images/down-arrow-false.svg);
}

/* Filled Input Controls END */

/* BUTTON STRUCTURE */

.theme-btn {
  height: 30px !important;
  min-width: 60px !important;
  padding-inline: 8px !important;
  font-size: var(--theme-context-fs) !important;
  text-align: center !important;
  border: 0 !important;
  border-radius: 4px !important;
}

.theme-modal-btn {
  height: 32px !important;
  min-width: 60px !important;
  padding-inline: 8px !important;
  font-size: var(--theme-context-fs) !important;
  text-align: center !important;
  border: 0 !important;
  border-radius: 4px !important;
}

.theme-action-btn {
  background-color: var(--theme-primary-color) !important;
  color: #fff !important;
  font-weight: 500 !important;
  box-shadow: 0 3px 6px rgb(223 215 215 / 16%), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.theme-action-modal-btn {
  background-color: var(--theme-warning-color) !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.theme-success-btn {
  background-color: var(--theme-success-color) !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.theme-action-btn:hover {
  background-color: #355496 !important;
}

:is(.theme-action-btn, .theme-tabular-action-btn) img {
  height: 17px;
  width: 17px;
}

.theme-cancel-btn {
  color: var(--theme-danger-color) !important;
  background-color: #fff !important;
  border: 1px solid var(--theme-danger-color) !important;
  font-weight: 500 !important;
}

.theme-secondary-btn {
  color: var(--theme-primary-color) !important;
  background-color: #fff !important;
  border: 1px solid var(--theme-primary-color) !important;
  font-weight: 500;
}

.theme-tertiary-btn {
  color: var(--theme-primary-color) !important;
  background-color: #fff !important;
  font-weight: 500;
}

.theme-outline-btn {
  height: 26px !important;
  min-width: 60px !important;
  padding-inline: 0px !important;
  padding-block-end: 4px !important;
  text-align: center !important;
  background-color: transparent !important;
  color: var(--theme-primary-color) !important;
  font-weight: 700 !important;
  font-size: var(--theme-context-fs) !important;
  border: 0 !important;
  border-block-end: 2px solid var(--theme-primary-color) !important;
  border-radius: 0 !important;
}

.theme-outline-btn:hover {
  color: var(--theme-focus-color) !important;
  border-color: var(--theme-focus-color) !important;
}

.theme-outline-cancel-btn {
  height: 26px !important;
  min-width: 60px !important;
  padding-inline: 0px !important;
  padding-block-end: 4px !important;
  text-align: center !important;
  background-color: transparent !important;
  color: var(--theme-danger-color) !important;
  font-weight: 700 !important;
  font-size: var(--theme-context-fs) !important;
  border: 0 !important;
  border-block-end: 2px solid var(--theme-danger-color) !important;
  border-radius: 0 !important;
}

.theme-outline-cancel-btn:hover {
  border-color: var(--theme-danger-hover-color) !important;
  color: var(--theme-danger-hover-color) !important;
}

.outlined-action-btn {
  height: 26px !important;
  padding-inline: 2px !important;
  padding-block-end: 4px !important;
  text-align: center !important;
  background-color: transparent !important;
  color: var(--theme-primary-color) !important;
  font-size: var(--theme-context-fs) !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.outlined-action-btn span {
  text-decoration: none;
  position: relative;
}

.outlined-action-btn span::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  border-bottom: 2px solid var(--theme-primary-color) !important;
}

.outlined-action-btn span:hover::after {
  border-color: var(--theme-focus-color) !important;
}

.outlined-action-btn:hover {
  color: var(--theme-focus-color) !important;
}

.theme-download-btn {
  background-color: #f4eafc !important;
  color: var(--theme-dark-color) !important;
}

.theme-external-action-btn {
  background-color: #fcedb9 !important;
  color: var(--theme-dark-color) !important;
}

.theme-tabular-action-btn {
  height: 30px !important;
  min-width: 58px !important;
  padding-inline: 8px !important;
  line-height: 20px !important;
  margin-top: 6.5px !important;
  background-color: var(--theme-primary-color) !important;
  color: #fff !important;
  text-align: center !important;
  border: 0 !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
}

.theme-tabular-edit-btn {
  height: 25px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--theme-primary-color) !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.theme-tabular-delete-btn {
  height: 25px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--theme-danger-color) !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.theme-tabular-delete-btn img {
  width: 24px;
  height: 24px;
}

.theme-tabular-outline-btn {
  height: 18px !important;
  line-height: 5px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--theme-primary-color) !important;
  border: 0 !important;
  border-block-end: 1px solid var(--theme-primary-color) !important;
  border-radius: 0 !important;
}

.theme-tabular-outline-btn:hover {
  color: var(--theme-focus-color) !important;
}

.theme-tabular-outline-cancel-btn {
  height: 18px !important;
  line-height: 5px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--theme-danger-color) !important;
  border: 0 !important;
  border-block-end: 1px solid var(--theme-danger-color) !important;
  border-radius: 0 !important;
}

.theme-tabular-outline-cancel-btn:hover {
  color: var(--theme-danger-hover-color) !important;
  border-color: var(--theme-danger-hover-color) !important;
}

.theme-tabular-active-btn {
  height: 18px !important;
  line-height: 5px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--theme-success-color) !important;
  border: 0 !important;
  border-block-end: 1px solid var(--theme-success-color) !important;
  border-radius: 0 !important;
}

.theme-tabular-inactive-btn {
  height: 18px !important;
  line-height: 5px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--theme-danger-color) !important;
  border: 0 !important;
  border-block-end: 1px solid var(--theme-danger-color) !important;
  border-radius: 0 !important;
}

.theme-tabular-secondary-btn {
  height: 18px !important;
  min-width: 58px !important;
  line-height: 5px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--theme-primary-color) !important;
  border: 0 !important;
  border-block-end: 1px solid var(--theme-primary-color) !important;
  border-radius: 0 !important;
}

.theme-tabular-hover-outline-btn {
  border-block-end: 0 !important;
  height: 18px !important;
  line-height: 5px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: var(--theme-primary-color) !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.theme-tabular-hover-outline-btn:hover {
  color: var(--theme-secondary-focus-color) !important;
  border-block-end: 1px solid var(--theme-secondary-focus-color) !important;
}

.theme-cancel-btn:hover {
  background-color: var(--theme-danger-btn-color) !important;
}

.theme-action-btn:hover,
.theme-tabular-action-btn:hover {
  background-color: var(--theme-secondary-focus-color) !important;
}

.theme-action-btn:disabled,
.theme-tabular-action-btn:disabled {
  background-color: var(--theme-disabled-color) !important;
  cursor: not-allowed !important;
  opacity: 0.65 !important;
}

.theme-action-btn:disabled:hover,
.theme-tabular-action-btn:disabled:hover {
  background-color: var(--theme-disabled-color) !important;
}

.theme-secondary-btn:hover,
.theme-tertiary-btn:hover,
.theme-tabular-outline-btn:hover {
  border-color: var(--theme-secondary-focus-color) !important;
  color: var(--theme-secondary-focus-color) !important;
}

.theme-secondary-btn:disabled,
.theme-tertiary-btn:disabled,
.theme-tabular-outline-btn:disabled .theme-tabular-hover-outline-btn:disabled {
  border-color: var(--theme-disabled-color) !important;
  color: var(--theme-disabled-color) !important;
}

.theme-secondary-btn:disabled:hover,
.theme-tertiary-btn:disabled:hover,
.theme-tabular-outline-btn:disabled:hover .theme-tabular-hover-outline-btn:disabled:hover {
  border-color: var(--theme-disabled-color) !important;
  color: var(--theme-disabled-color) !important;
}

.theme-back-btn {
  background: #ffffff !important;
  color: #2b4378 !important;
}

.theme-back-btn:hover {
  background: #ffffff8c !important;
}

/* MAIN CONTENT STRUCTURE*/

.theme-content-container {
  width: 92% !important;
  margin-inline: auto !important;
  margin-block-end: 16px !important;
  padding: 7px 7px !important;
  border-radius: 0px !important;
  background-color: #fff !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* max-height: 708px; */
  flex: 1;
  min-height: 80vh;
}

.theme-dashboard-container {
  width: 92% !important;
  margin: auto !important;
  padding: 0 !important;
  border-radius: 6px !important;
  background-color: var(--theme-body-background) !important;
  /* max-height: 708px; */
  min-height: 75%;
}

.theme-content-container .theme-content-heading {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  gap: 10px !important;
  height: 40px !important;
  background-color: #fff !important;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  padding-inline: 8px !important;
  padding-block-start: 10px !important;
  padding-block-end: 8px !important;
  border-block-end: 1px solid #dddddd !important;
}

.theme-content-container .theme-content-heading :is(h1, h2, h3, h4, h5, h6) {
  font-size: var(--theme-heading-fs) !important;
  font-weight: 500 !important;
  margin-block: 0 !important;
  background-color: #fff !important;
}

.theme-content-container .theme-content-filters {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  gap: 16px;
  min-height: 47px !important;
  padding-block: 8px !important;
  padding-inline-start: 8px !important;
  padding-inline-end: 16px !important;
  margin-block: 0px !important;
  border-block-end: 1px solid #dddddd !important;
}

/* TABLE STRUCTURE */

.table-responsive {
  width: 100%;
}

.theme-content-container .table-responsive {
  display: flex !important;
  flex-direction: column !important;
  min-height: 510px !important;
  height: auto;
  margin-bottom: 60px;
}

.theme-content-container .table-fixed-height+.table-responsive {
  min-height: 16vh !important;
}

.theme-content-container .table-max-height+.table-responsive {
  min-height: auto !important;
  height: 66vh !important;
}

.table> :not(:first-child) {
  border: 0;
}

.table th {
  outline: none !important;
}

.table td,
.table th {
  border: 0 !important;
  white-space: nowrap;
  cursor: default;
}

table thead th {
  border: 0 !important;
}

.table-hover>tbody>tr:hover>* {
  background-color: transparent !important;
}

:is(.modal, .theme-content-container) .theme-content-table {
  padding-block-end: 4px !important;
  margin-block-end: 0px !important;
  --bs-table-bg: transparent !important;
}

:is(.modal, .theme-content-container) .theme-content-table thead {
  height: 37px !important;
  background-color: #fff !important;
  border-block-end: 1px solid #888888;
  color: var(--theme-secondary-color) !important;
  font-size: var(--theme-table-fs) !important;
  font-weight: 400;
}

:is(.modal, .theme-content-container) .theme-content-table thead th {
  height: 37px !important;
  line-height: 33px !important;
  padding: 0 !important;
  padding-inline: 8px !important;
  font-weight: 400 !important;
}

:is(.modal, .theme-content-container) .theme-content-table thead.theme-thead-large {
  height: 48px !important;
  line-height: 39px !important;
}

:is(.modal, .theme-content-container) .theme-content-table thead.theme-thead-large th {
  height: 48px !important;
  line-height: 39px !important;
}

:is(.modal, .theme-content-container) .theme-content-table tbody {
  font-size: var(--theme-table-fs) !important;
}

:is(.modal, .theme-content-container) .theme-content-table tbody tr,
:is(.modal, .theme-content-container) .theme-content-table tbody td {
  height: 46px !important;
  line-height: 45px !important;
  padding: 0 !important;
}

:is(.modal, .theme-content-container) .theme-content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

/* :is(.modal, .theme-content-container) .theme-content-table tbody tr:last-child {
  border-bottom: none;
} */

:is(.modal, .theme-content-container) .theme-content-table.table-hover>tbody>tr.selected-row>*,
:is(.modal, .theme-content-container) .theme-content-table.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: var(--theme-hover-color) !important;
  --bs-table-hover-color: var(--theme-hover-color) !important;
  --bs-table-hover-bg: var(--theme-hover-color) !important;
}

:is(.modal, .theme-content-container) .theme-content-table.table-hover>tbody>tr.no-hover-row:hover>* {
  --bs-table-accent-bg: transparent !important;
  --bs-table-hover-color: transparent !important;
  --bs-table-hover-bg: transparent !important;
}

:is(.modal, .theme-content-container) .theme-content-table tbody td {
  padding-inline: 8px !important;
  color: var(--theme-dark-color) !important;
  font-weight: 400;
}

:is(.modal, .theme-content-container) .theme-table-spaced thead th {
  padding-inline: 16px !important;
}

:is(.modal, .theme-content-container) .theme-table-spaced tbody td {
  padding-inline: 16px !important;
}

:is(.modal, .theme-content-container) .theme-content-basic {
  padding-inline: 8px !important;
  padding-block-end: 30px !important;
  border-block-end: 1px solid #ddd !important;
}

/* PAGINATION STRUCTURE */

.pagination {
  height: 50px !important;
  width: 100% !important;
  justify-content: end !important;
  background: white !important;
  padding: 10px 0px 10px !important;
  margin: 0px !important;
}

.pagination .previous {
  align-items: center;
  justify-content: center;
  display: flex;
}

.pagination-wrapper.pagination {
  justify-content: space-between !important;
  align-items: center !important;
  padding-block: 0 !important;
  padding-inline-end: 10px !important;
  padding-inline-start: 24px !important;
  /* margin-block-end: 16px !important; */
  border-radius: 10px !important;
}

.pagination-separator {
  height: 20px !important;
  background: var(--theme-body-background) !important;
}

.pagination .next {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  align-items: center;
  justify-content: center;
  display: flex;
}

.pagination li a {
  color: var(--theme-dark-color) !important;
  border-radius: 0px !important;
}

.pagination li.active a {
  border: 1px solid #2b4378 !important;
  background-color: #2b4378 !important;
  border-radius: 4px !important;
  border-color: #2b4378 !important;
  color: white !important;
}

.pagination-text-content {
  font-size: var(--theme-small-fs) !important;
  color: #888888 !important;
  font-weight: 400 !important;
}

/* React Quill */

.ql-toolbar.ql-snow {
  border: 1px solid var(--theme-secondary-color) !important;
  min-height: 32px !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #d9d9d9 !important;
  font-family: "Lato", "Roboto", serif !important;
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.ql-snow .ql-formats {
  width: fit-content !important;
  display: flex !important;
  align-items: center !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--theme-primary-color) !important;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: auto;
  left: 60px;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  font-family: "Lato", "Roboto", serif !important;
  border: 1px solid var(--theme-secondary-color) !important;
  border-top: 0 !important;
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}

.ql-toolbar.ql-snow .ql-formats:nth-child(3) {
  margin-inline-start: auto;
}

/* TEMPLATE PREVIEWS */

.preview-container {
  position: relative;
  width: auto;
  float: left;
}

.preview-container>div {
  position: relative;
  width: auto;
  float: left;
}

.preview-container img {
  max-width: 100%;
  display: block;
}

.selected-preview {
  border: 6px solid var(--theme-success-color);
  border-radius: 8px;
}

input[type="checkbox"].preview-checkbox {
  cursor: pointer !important;
  width: 13.5px !important;
  height: 13.5px !important;
  margin-top: 0 !important;
  position: absolute;
  right: 10px;
  top: 10px;
}

.thumbnail {
  z-index: 999;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease;
}

.thumbnail-top {
  position: relative;

  top: 10%;
  left: 30%;
  transform: scale(2.5);
}

.thumbnail-bottom {
  position: relative;
  top: -30%;
  left: 30%;
  transform: scale(2.5);
}

.modal-template-settings .modal-dialog {
  max-width: 1100px !important;
}

/* Payment Processing Gif Styles */
.gif-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.centered-gif {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 422px;
}

@media screen and (max-width: 1050px) {
  .modal-template-settings .modal-dialog {
    max-width: 500px !important;
    margin-inline: auto !important;
  }
}

/* MEDIA QUERIES */

@media screen and (min-width: 1860px) {
  .main-container {
    width: 80% !important;
    margin-inline: auto;
    padding: 0;
  }

  .theme-dashboard-container {
    width: 80% !important;
    margin: auto;
    padding: 0;
  }

  .main-container-full {
    background: var(--theme-primary-color);
    padding: 0;
  }

  .main-container-full.main-footer {
    background: #fff;
    margin-top: 37px !important;
  }

  .main-container-content {
    width: 80% !important;
    margin: auto;
    display: flex;
    padding: 0;
  }

  .theme-content-container {
    width: 80% !important;
    min-height: 86vh !important;
    min-height: 86svh !important;   
    margin-inline: auto;  
  }   

  .placeholder-glow {
    min-height: 6.4vh !important;
  }

  .theme-content-container .table-responsive {
    min-height: 65vh !important;
  }

  .tab-content {
    width: 80% !important;
    margin: auto;
  }

  .theme-progress-tabs+.tab-content {
    display: none !important;
  }
}

@media screen and (max-width: 1859px) {
  .main-container {
    width: 92%;
    margin-inline: auto;
    padding: 0;
  }

  .theme-dashboard-container {
    width: 92% !important;
    margin: auto;
    padding: 0;
  }

  .main-container-full {
    background: var(--theme-primary-color);
    padding: 0;
  }

  .main-container-full.main-footer {
    background: #fff;
  }

  .main-container-content {
    width: 92%;
    margin: auto;
    display: flex;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {

  .theme-dashboard-container,
  .theme-content-container,
  .main-container,
  .main-container-content {
    width: 95% !important;
  }

  .popover {
    inset: 7px auto auto 0px !important;
  }

  .notifications-dropdown-menu {
    top: 138% !important;
    left: -18rem !important;
  }
}

/* SCROLL BAR */

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

.theme-content-container .table-responsive {
  scrollbar-color: var(--theme-primary-color) transparent !important;
  scrollbar-width: thin !important;
}

.theme-content-container .table-responsive::-webkit-scrollbar {
  display: initial !important;
  width: 8px !important;
  height: 8px !important;
}

.theme-content-container .table-responsive::-webkit-scrollbar-thumb {
  background-color: var(--theme-primary-color);
  border-radius: 5px;
}

.theme-content-container .table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: var(--theme-secondary-focus-color);
}

.theme-content-container .table-responsive::-webkit-scrollbar-track {
  background-color: transparent;
}

.table-scroll {
  /* width: 100%; */
  max-height: 225px;
  overflow: hidden;
  overflow-y: scroll;
}

/* SWITCH START */

/* The switch - the container for the toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider - the moving part */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

/* The slider's appearance when it's checked (on) */
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

/* Change the background color of the slider when it's checked (on) */
input:checked+.slider {
  background-color: #2196f3;
}

/* Move the slider when it's checked (on) */
input:checked+.slider:before {
  transform: translateX(20px);
}

/* SWITCH END  */

/* Table Scroll */

.theme-table-scroll-container {
  position: relative;
  padding-bottom: 70px;
  overflow-y: auto;
  scrollbar-color: var(--theme-primary-color) transparent;
  scrollbar-width: thin;
}

.table-scroll-fixed-height {
  height: 300px !important;
  /* margin-bottom: 24px; */
}

.theme-table-scroll-container thead.theme-table-scroll-head {
  position: sticky !important;
  top: 0 !important;
  border-bottom: none;
  font-size: var(--theme-table-fs) !important;
}

.theme-table-scroll tr th {
  border-bottom: 1px solid #888888 !important;
}

/* Customize the scrollbar */
.theme-table-scroll-container::-webkit-scrollbar {
  width: 10px;
  /* Adjust as needed */
}